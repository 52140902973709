import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import {
  APIErrorResProps,
  APIResponse,
  ApiSuccessResponse,
} from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IChartDetails,
  IChartRateDetails,
  IGetChart,
} from "@crema/types/models/dairy/Manage-Chart/Chart";
import {
  ADD_CHART,
  DELETE_CHART,
  EDIT_CHART,
  EDIT_CHART_RATE_DETAILS,
  GET_CHART,
  GET_CHARTS,
  INIT_ADD_CHART,
  INIT_CHARTS_FILTER,
  SET_CHARTS_FILTER,
  UPLOAD_CHART_RATE_DETAILS,
} from "@crema/types/actions/dairy/Manage-Chart/Chart.actions";

export const getCharts = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/chart", { params: filter })
      .then((response: APIResponse<IGetChart>) => {
        handleApiResponse(dispatch, response, GET_CHARTS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateChart = (
  method: "post" | "put",
  data: IChartDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/chart${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IChartDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_CHART : EDIT_CHART,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteChart = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete(`/chart/${id}`)
      .then((response: APIResponse<IChartDetails>) => {
        handleApiResponse(dispatch, response, DELETE_CHART);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getChartById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/chart/${id}`)
      .then((response: APIResponse<IChartDetails>) => {
        handleApiResponse(dispatch, response, GET_CHART);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initAddChart = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_CHART });
  };
};

export const updateChartRateDetails = (
  data: IChartRateDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`/addChartDetails/${id}`, data)
      .then((response: APIResponse<ApiSuccessResponse>) => {
        handleApiResponse(dispatch, response, EDIT_CHART_RATE_DETAILS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const uploadChartRateDetails = (data: any, id?: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    console.log(data);
    jwtAxios
      .post(`/uploadChart/${id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: APIResponse<ApiSuccessResponse>) => {
        handleApiResponse(dispatch, response, UPLOAD_CHART_RATE_DETAILS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
